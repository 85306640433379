import React from 'react';

const App = (router: any) => {
  return (
    <React.Fragment>
      {router.children}
    </React.Fragment>
  );
}

export default App;
